@font-face {
  font-family: 'Signika';
  src: url('./assets/fonts/signikanegative-variablefont_wght-webfont.woff2') format('woff2'),
  url('./assets/fonts/signikanegative-variablefont_wght-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'Quicksand';
  src: url('./assets/fonts/quicksand-medium-webfont.woff2') format('woff2'),
  url('./assets/fonts/quicksand-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: "Quicksand", sans-serif;
  font-weight: 500;
  //color: #773641;
  //font-weight: normal;
  //font-weight: bold;
}


body {
  //background-color: #f2f2f2;
}

.Splash {
  display: flex;
  width: 100%;
  height: auto !important;
  flex-direction: column;
  //justify-content: center;
  align-items: center;

  &_Header {
    width: 100%;
    max-width: 300px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      background: none;
      border: none;
      font-size: 16px;
      font-family: "Quicksand", sans-serif;
      color: #321410;
    }
  }

  &_Inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    max-width: 300px;
    width: 100%;
    border-radius: 10px;
    text-align: center;
    //box-shadow: rgba(0,0,0,0.2) 0 0 15px;
  }

  &_Buttons {
    //display: flex;
    //flex-direction: column;
    //
    //@media (min-width: 480px) {
    //  flex-direction: row;
    //}
  }

  img {
    height: 60px;
    margin-bottom: 10px;
  }

}

.Main {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  //max-width: 600px;
  width: 100vw;
  margin: 0 auto;
  background-color: #fff;
  min-height: 1px;
}

.Main_Header {

  padding: 5px 5px 5px 16px;
  text-align: center;
  border-bottom: 3px solid #f2f2f2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #3858B2;
  color: #fff;

  .Main--portello & {
    background-color: #E73F32;
  }

  .Main--venezia & {
    background-color: #BD995C;
  }


  &Right {
    display: flex;
    align-items: center;
  }

  a {
    color: #fff;
    text-decoration: none;
  }

  button {
    background: none;
    border: none;
    font-size: 16px;
    font-family: "Quicksand", sans-serif;
    color: #fff;
    margin-left: 10px;
    padding: 5px 15px;
  }

  img {
    height: 60px;
    vertical-align: bottom;


  }

  &Restaurant {
    text-align: right;
    font-family: "Signika", sans-serif;
    text-transform: uppercase;
    line-height: 1em;
  }

  &--white {
    background: #fff;
    color: #000;

    a,
    button {
      color: #000;
    }
  }
}

.Main_Body {

  flex: 1;
  padding: 10px 20px;
  overflow: scroll;
  max-width: 500px;
  width: 100%;
  margin: 0 auto;

}

.Main_Footer {

  border-top: 3px solid #f2f2f2;
  display: flex;
  justify-content: space-between;
  font-weight: bold;

  sup {
    font-size: 10px;
    top: -6px;
    position: relative;
    right: -3px;
  }

  a {
    width: 52%;
    &:first-child {
      width: 48% !important;
    }
    text-align: center;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    letter-spacing: 0.02em;
    font-weight: 600;
    text-decoration: none;
    color: #000;
    justify-content: center;
    padding: 2px 0 5px 0;

    &:first-child {
      border-right: 2px solid #f2f2f2;
    }

  }

  img {
    width: auto;
    height: 30px;
    margin-right: 10px;
  }

}


.Button {
  display: inline-block;
  border-radius: 5px;
  background-color: #3858B2;
  text-transform: uppercase;
  color: #fff;
  text-decoration: none;
  padding: 8px 10px;
  font-weight: 600;
  letter-spacing: 0.02em;
  border: none;
  box-shadow: none;
  font-size: 16px;

  .Main--portello & {
    background-color: #E73F32;
  }

  .Main--venezia & {
    background-color: #BD995C;
  }

  &:disabled {
    background-color: #cbcbcb;
  }

  &:not(:disabled) {
    cursor: pointer;

  }

  &._big {
    padding: 14px 30px 18px 30px;
    font-size: 18px;
  }

  &._full {
    width: 100%;
  }
}

.ButtonLink {
  display: inline-block;
  border-radius: 5px;
  background-color: transparent;
  color: #000000;
  text-decoration: none;
  padding: 8px 10px;
  font-weight: 600;
  letter-spacing: 0.02em;
  border: none;
  box-shadow: none;
  cursor: pointer;
  font-family: "Signika", sans-serif;

  &._big {
    padding: 20px 30px
  }
}

.PageHome {

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

}


.Page {

  h1 {
    margin-top: 0
  }


}

.MenuItem {
  width: 100%;
  margin-bottom: 25px;

  h3 {
    margin: 0;
    font-size: 20px;
    flex: 1;
    font-weight: bold;
    margin: -3px 0 0 0;
  }

  p {
    margin: 0;
    //color: #949494;
    font-size: 16px;
    line-height: 19px;
  }

  &_Top {
    width: 100%;
    display: flex;
    margin-bottom: 1px;

    &Price {
      margin-left: 10px;
    }
  }

  &_Allergen {
    margin-left: 5px;
    img {
      width: 25px;
      vertical-align: middle;
    }
  }
}

.Allergens {
  border-radius: 10px;
  padding: 10px 15px;
  background-color: #f4f4f4;
  font-size: 80%;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;

  img {
    width: 25px;
    height: auto;
    margin-right: 10px;
  }

  div {
    display: flex;
    line-height: 1em;
    flex: 0 0 50%;
    align-items: center;
    margin: 5px 0;
    &:not(:last-child) {
      //margin-right: 10px;
    }
  }

}

.MenuOption {

  &.disabled {
    opacity: 0.4;
  }

  input {
    display: none;
  }


  label {
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 10px 10px 10px 50px;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 10px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 1px solid #e1e1e1;
      background-color: transparent;
      margin-top: -10px;
      transition: all 0.3s;
    }

    &::after {
      content: "";
      background: url(./assets/tick-icon.svg) top center no-repeat;
      background-size: 100% auto;
      width: 25px;
      height: 20px;
      position: absolute;
      top: 50%;
      left: 14px;
      margin-top: -13px;
      opacity: 0;

    }

  }

  input:checked ~ label::after {
    //border-color: #e83e33;
    //background-color: #e83e33;
    opacity: 1;
  }

  input:checked ~ label::before {
    background-color: #e1e1e1;
  }

  .MenuItem {
    margin-bottom: 0;
  }
}

h1,
h2 {
  color: #321410;
  font-family: "Signika", sans-serif;
  letter-spacing: -0.05em !important;
  font-size: 30px;
}

h2 {
  font-size: 40px;
  font-family: "Signika", sans-serif;
  //text-transform: uppercase;
}

hr {
  border: none;
  background-color: #f2f2f2;
  height: 2px;
  display: block;
}

.OrderStep {
  display: flex;
  flex-direction: column;
  height: 100%;

  &_Top {

    border-bottom: 2px solid #f2f2f2;
    padding-bottom: 10px;

    h1 {
      text-align: center;
      margin: 0;
    }

    &Bullets {
      display: flex;
      width: 100%;
      justify-content: space-between;
      height: 30px;
      margin-top: 10px;
      div {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        text-align: center;
        font-size: 12px;
        line-height: 18px;
        padding-left: 1px;
        background-color: #F4CCB0;
        color: #000;

        &.active {
          color: #fff;
          background-color: #000;
        }
      }
    }

  }

  &_Inner {
    flex: 1;
    overflow: scroll;
  }

  &_Buttons {

    border-top: 2px solid #f2f2f2;
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &Text {
      text-align: center;
      font-size: 13px;
      padding-left: 15px;
      padding-right: 15px;
      line-height: 14px;
    }

    .btn {
      padding: 5px 10px;
    }

  }
}

.CartItem {
  position: relative;
  border: 1px solid #321410;
  border-radius: 2px;
  padding: 10px;
  margin-bottom: 15px;
  display: flex;
  color: #321410;

  &_Inner {
    flex: 1
  }

  &_Top {
    font-size: 13px;
  }

  button {
    position: absolute;
    top: 10px;
    right: 5px;
    border: none;
    background-color: transparent;
    cursor: pointer;

    img {
      width: 20px;
    }
  }

}

.Loader {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.CapitaniatoButton,
.PortelloButton,
.VeneziaButton {
  width: 100%;
  padding-top: 67%;
  background-size: 100% auto !important;
  color: #321410;
  display: block;
  position: relative;
  text-align: center;

  div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-transform: uppercase;
    font-weight: bold;
    font-size: 30px;
    line-height: 23px;
    font-family: "Signika", sans-serif;
    span {
      font-size: 70%;
    }
  }
}

.VeneziaButton {
  background: url(./assets/venezia-button.svg) center no-repeat;
}

.CapitaniatoButton {
  background: url(./assets/capitaniato-button.svg) center no-repeat;
  margin-bottom: 10px;
}

.PortelloButton {
  background: url(./assets/portello-button.svg) center no-repeat;
  margin-bottom: 10px;
}

.HomeButton {

  position: relative;
  display: block;
  width: calc(50% - 10px);
  margin: 5px 5px;
  background-color: #5BA8DB;
  border-radius: 2px;
  
  .Main--portello & {
    background-color: #E73F32;
  }

  .Main--venezia & {
    background-color: #BD995C;
  }

  img.HomeButton_Placeholder {
    width: 100%;
    height: auto;
    vertical-align: bottom;
  }

  img.HomeButton_Icon {
    position: absolute;
    bottom: 45%;
    left: 50%;
    width: 46%;
    transform: translateX(-50%);
    vertical-align: bottom;
  }

  span {
    position: absolute;
    bottom: 10px;
    left: 0;
    color: #321410;
    font-family: "Signika", sans-serif;
    text-transform: uppercase;
    line-height: 1.2em;
    font-size: 18px;
    width: 100%;
    text-align: center;
    padding: 0 10px;

    @media (min-width: 768px) {
      font-size: 22px;
      line-height: 1.3em;
      bottom: 20px;
      padding: 0 15px;
    }
  }

}

.Cart {

  &_Container {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }

  &_Inner {
    flex: 1;
  }

}
